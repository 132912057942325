import React from "react";
import { DojoGlowProvider } from "../components/partials/dojo-glow/context";
import { DojoGlowPage } from "../components/partials/dojo-glow/page";

const DojoGlow = () => {
  return (
    <DojoGlowProvider>
      <DojoGlowPage />
    </DojoGlowProvider>
  );
};

export default DojoGlow;
